.ModalBody {
  background-color: #929191;
}

.Title {
  border-bottom: 2px solid black !important;
  text-transform: uppercase;
  font-weight: 800;
  padding-bottom: 10px !important;
  text-align: center;
}

.ProfileButton {
  margin-left: 25px;
}

.BasicInfoButton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.StatusHeaderText {
  margin: auto;
}

.StatusBannerActionRow {
  padding-bottom: 20px !important;
}
