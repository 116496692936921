
section#welcome {

  h1 {
    padding-top: 25px;
    text-align: center;
  }

  p {
    font-size: 1.2em;
  }

  p.firebaseui-tos {
    font-size: 0.7em;
  }

  p.firebaseui-error {
    font-size: 1em;
  }

  form {
    button {
      text-align: right;
    }

    button.firebaseui-id-submit {
      text-align: center;
    }
  }

}
