.EventSlotDiv {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.EventStartTimeDiv {
  margin: 0 1rem;
}

.EventNameDiv {
  border: 1px solid black;
  padding: 5px 1rem;
  margin-right: 1rem;

}

.EventNameDiv:hover {
  cursor: pointer;
}

.EventCheckInOutButton {
  border: none;
  flex-wrap: nowrap;
  padding: 5px 1rem;
  background-color: lightgreen;
  color: white;
}

