.banner-details{
    width: 100%;
}

.bannerDetailsDiv {
    height: 280 + 'px';
    margin-bottom: 10 + 'px';
}

.bannerHeader {
    display: flex;
    align-items: center;
}

.bannerHeaderText {
    margin-bottom: 0;
    margin-top: 0;
}

.bannerHeaderDivider {
    margin-top: 0;
}
